
































































import { Component, Vue } from 'vue-property-decorator'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueUploadComponent from 'vue-upload-component'
import firebase from 'firebase/app'
import 'firebase/firestore'
import markdownIt from "markdown-it"

@Component({
  components: {
    ClipLoader,
    VueUploadComponent
  },
})
export default class NewsCreate extends Vue {
  isUploading: boolean = true
  createNews: any = {text: '', onlyLogin: false}
  news: any[] = []
  md = new markdownIt({html: false, breaks: true})

  mounted () {
    firebase.firestore().collection('news').orderBy('createTime', 'desc').get().then((news) => {
      news.forEach((data) => {
        let newsData = data.data()!
        newsData.id = data.id
        this.news.push(newsData)
      })
    }).finally(() => {
      this.isUploading = false
    })

    firebase.firestore().collection('contacts').where('complete', '==', false).orderBy('createTime', 'desc').get()
    .catch(() => {
      this.$router.push('/404')
    })
  }

  create() {
    this.isUploading = true

    let date = new Date()
    if (this.createNews.important) {
      date.setFullYear(2150)
      this.createNews.createTime = date
    } else {
      this.createNews.createTime = firebase.firestore.FieldValue.serverTimestamp()
    }
    this.createNews.updateTime = firebase.firestore.FieldValue.serverTimestamp()

    if (this.createNews.id) {
      let id = this.createNews.id
      delete this.createNews.id
      firebase.firestore().collection('news').doc(id).set(this.createNews)
        .then(() => {
          this.isUploading = false
        })
    } else {
      firebase.firestore().collection('news').add(this.createNews)
        .then((data) => {
          this.news.unshift(this.createNews)
          this.createNews.id = data.id
          this.isUploading = false
        })
    }
  }

  newNews() {
    this.createNews = {text: '', onlyLogin: false}
  }

  compiledMarkdownText() {
    return this.md.render(this.createNews.text)
  }

  clickList(news: any) {
    if (this.createNews.id == news.id) {
      this.createNews = {text: '', onlyLogin: false}
    } else {
      this.createNews = news
    }
  }
}
